import {
  useTheme
} from '@mui/material';

import localLogoImage from '../../assets/images/logo_vigias.png';

function Logo() {
  const theme = useTheme();

  return (
    <img src={localLogoImage} alt="Vigias Logo" style={{ width: '250px', height: '250px' }} />
  );
}

export default Logo;
