import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const Overview = Loader(lazy(() => import('src/content/overview')));
// Dashboards
const ShowReports = Loader(lazy(() => import('src/content/dashboards/showReports')));
// Applications
const UserProfile = Loader(lazy(() => import('src/content/userProfile')));

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          }
        ]
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="showReports" replace />
      },
      {
        path: 'showReports',
        element: <ShowReports />
      }
    ]
  },
  {
    path: 'userProfile',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="userProfile" replace />
      },
      {
        path: 'userProfile',
        element: <UserProfile />
      }
    ]
  }
];

export default routes;
